import { buildGTADataLayerPurchase } from "@mappers/GoogleAnalytics/GoogleAnalytics.mapper";
import { OrderConfirmationDataModel } from "@models/OrderConfirmation"
import { setUserFacebookPixelSent } from "@redux/localStorage/user";
import { renderGetPlateStyleUrl } from "@utils/ApiUrls";
import Axios from "axios";

const reactPixel = require('react-facebook-pixel');

const push = (data :any) =>{    
    if(window.dataLayer !== undefined && window.dataLayer !== null){
        console.log('data layer is initialised.')
    }else{
        console.log('data layer is not initialised.')
    }
    window.dataLayer.push(data)
}

const trackEvent = (event:any,data:any) =>{
    window.dataLayer.push({
        event: event,
        attributes:{
            'u4': data.combination,
            'u5': data.isAvailable,
            'u7': data.plateDesignId,
            'u8': data.plateForegroundColourId,
            'u9': data.captionTopText,
            'u10': data.captionBottomText,
            'u11': data.orderType,
            'u13': data.itemID,
            'u14': data.itemName,
            'u15': data.variant,
            'u18': data.restyleVSnewdesign,
        }
    })
}

const trackEventGA4 = (data: any) =>{    
    if (data && data.ecommerce){
        window.dataLayer.push({ecommerce: null });
    }
    push(data)
}

const sendFacebookPixelPurchase = (env:string, transactionId: string, orderConfirmationData: OrderConfirmationDataModel) => {
    if (env === 'production') {
        reactPixel.default.init('313542812355980');
        let totalOrderPrice = 0;
        orderConfirmationData.Items.forEach((item) => {
            totalOrderPrice += (item.PriceExGst + item.PriceGst);
        });
        reactPixel.default.track('Purchase', {
            content_type: 'product',
            contents: [
                {
                    'id': transactionId,
                    'quantity': orderConfirmationData.Items.length
                }
            ],
            value: totalOrderPrice,
            currency: 'NZD'
        });
        setUserFacebookPixelSent(transactionId);
    }
}
const getPlateDesignNameAndPlateStyleCode = async (apiUrl:string, frontPlateDesignId: number, frontForegroundColorId: number, backPlateDesignId: number, backForegroundColorId: number) => {
    const url = renderGetPlateStyleUrl(apiUrl, frontPlateDesignId, frontForegroundColorId, backPlateDesignId, backForegroundColorId);    

    const result = await Axios.get(url)
    return result.data;
}
const pushGAPurchase =  (orderConfirmationData: OrderConfirmationDataModel) =>{
    const data = buildGTADataLayerPurchase(orderConfirmationData);
    push(data);
}

export const GAService = {
    push: push,
    trackEvent: trackEvent,
    trackEventGA4: trackEventGA4,
    pushGAPurchase: pushGAPurchase,
    sendFacebookPixelPurchase: sendFacebookPixelPurchase,
    getPlateDesignNameAndPlateStyleCode: getPlateDesignNameAndPlateStyleCode
}